.footer {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: $secondary;
  color: $white;
}

.copyright {
  background-color: darken($secondary, 10%);
}

