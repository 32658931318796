* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: rgba($primary, 0.4);
}

::-moz-selection {
  background: rgba($primary, 0.4);
}


// ↓ ↓ ↓ DEV STYLING - REMOVE FOR PRODUCTION ↓ ↓ ↓
// body:after {
//   color: white;
//   font-size: 12px;
//   padding: 5px;
//   font-weight: bold;
//   right: 10px;
//   position: fixed;
//   text-align: center;
//   text-transform: uppercase;
//   bottom: 10px;
//   z-index: 9999;
//   background: $primary;

//   @each $name,
//   $value in $grid-breakpoints {
//     @include media-breakpoint-up($name) {
//       content: '#{$name}';
//     }
//   }
// }

// #__bs_notify__ {
//   top: initial !important;
//   bottom: 1rem;
//   right: 0 !important;
//   border-radius: 0 !important;
//   background: $primary !important;
//   padding: 0.5rem 1rem !important;
//   font-size: smaller !important;
//   max-width: 7rem;
//   text-align: left !important;
// }

// ↑ ↑ ↑ DEV STYLING - REMOVE FOR PRODUCTION ↑ ↑ ↑
body {
}

.freestyle {
  font-family: 'FreeStyleScript';
  letter-spacing: 0.05em;
  font-size: 2.25rem;
  line-height: 1.2;
}

#contact {
  form {
    padding: 1rem;
    background: white;
  }

  button {
    font-family: 'CastIron';
    letter-spacing: 0.05em;
    font-size: 2rem;
    line-height: 1;
    border-radius: 0;
  }
}

input::placeholder, textarea::placeholder {
  font-family: 'CastIron';
  letter-spacing: 0.05em;
  font-size: 2.25rem;
  line-height: 1.2;
}

.floating-label-form-group-with-value label {
  font-family: 'CastIron';
  letter-spacing: 0.05em !important;
  font-size: 2.25rem !important;
  line-height: 1 !important;
  margin-top: 1rem;
}

.form-control {
  height: auto;
  padding: 0;
}

.control-group {
  .help-block {
    display: none;
    margin-top: 0.5rem;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  &.warning {
    .help-block {
      display: block;
    }
  }
}

h2 {
  margin-bottom: 5rem !important;
  span {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2rem;
    
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      height: 2px;
      bottom: -2rem;
      left: 0;
      background: white;
      box-shadow: 0px 0 black, 0 2px black, 2px 0 black, 0 0px black;
    }
  }
}

#nav {
  display: flex;
  justify-content: center;
}

#imprint, #privacy {
  font-size: 1.2rem;
  font-family: 'CastIron';
  letter-spacing: 0.05em !important;
  padding: 0 0.5rem;
  text-align: left !important;
  &:hover {
    cursor: pointer;
  }  
}

#imprint-content {
  display: none;
  padding: 1rem;
}

#privacy-content {
  display: none;
  padding: 1rem;
  h4 {
    font-size: 1.75rem !important;
  }
  h5 {
    font-size: 1.5rem !important;
  }
}

@media(max-width:991px) {
  .navbar-collapse {
    background: $primary;
    padding: 0 1rem;
  
    .nav-link {
      &:hover {
        color: darken($primary, 20%) !important;
      }
    }
  }
  
  #mainNav .navbar-toggler {
    font-size: 1.2rem !important;
    font-family: 'CastIron' !important;
    letter-spacing: 0.05em !important;
  }
}


.portfolio-item {
    margin-left: auto;
    margin-right: auto;
}