@mixin body-font() {
  font-family: 'FreeStyleScript';
}

@mixin heading-font() {
  font-weight: 200;
  font-family: 'CastIron';
  letter-spacing : 0.3px;
}

@font-face {
  font-family: "CastIron";
  src: url("../fonts/Cast_Iron-Condensed.otf");
}

@font-face {
  font-family: "FreeStyleScript";
  src: url("../fonts/FreestyleScriptPlain.otf");
}
