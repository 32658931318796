// Bootstrap overrides for this template
a {
  color: $primary;
  &:focus,
  &:hover,
  &:active {
    color: darken($primary, 10%);
  }
}

.btn {
  border-width: 2px;
}

section {
  padding: 3rem 0;
}

.bg-primary {
  background-color: $primary !important;
}

.portfolio {
  background-color: $primary !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  //color: $secondary !important;
  color: white !important;
  text-shadow: 0px 0 black, 0 2px black, 2px 0 black, 0 0px black;
}

.navbar-collapse {
  font-size : 28px
}

header.masthead {
  padding-bottom: 1em;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($primary, 10%);
    border-color: darken($primary, 10%);
  }
}

.btn-secondary {
  background-color: $secondary;
  border-color: $secondary;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($secondary, 10%);
    border-color: darken($secondary, 10%);
  }
}

.lead {
  font-size: 2.25rem;
  font-weight: 300;
  text-shadow: 0px 0 black, 0 0px black, 0px 0 black, 0 0px black;
}
