// Typography
body {
  @include body-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include heading-font;
}

// Custom Horizontal Rule with Star Icon
hr.star-light,
hr.star-dark {
  max-width: 15rem;
  padding: 0;
  text-align: center;
  border: none;
  border-top: solid 0.25rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

hr.star-light:after,
hr.star-dark:after {
  position: relative;
  top: -.8em;
  display: inline-block;
  padding: 0 0.25em;
  content: '\f005';
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 2em;
}

hr.star-light {
  border-color: $white;
}
hr.star-light:after {
  color: $white;
  background-color: $primary;
}

hr.star-dark {
  border-color: $secondary;
}
hr.star-dark:after {
  color: $secondary;
  background-color: white;
}

// Section Padding Settings
section {
  padding: 6rem 0;
  h2 {
    font-size: 2.25rem;
    line-height: 2rem;
  }
  @media (min-width: 992px) {
    h2 {
      font-size: 3rem;
      line-height: 2.5rem;
    }
  }
}

// Custom Button Size
.btn-xl {
  padding: 1rem 1.75rem;
  font-size: 1.25rem;
}

// Custom Rounded Social Button
.btn-social {
  width: 3.25rem;
  height: 3.25rem;
  font-size: 1.25rem;
  line-height: 2rem;
}

// Scroll to Top Button
.scroll-to-top {
  z-index: 1042;
  right: 1rem;
  bottom: 1rem;
  display: none;
  a {
    width: 3.5rem;
    height: 3.5rem;
    background-color: fade-out($gray-900, 0.5);
    line-height: 3.1rem;
  }
}
