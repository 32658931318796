header.masthead {
  padding-top: calc(6rem + -46px);
  padding-bottom: 6rem;
  h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
  h2 {
    font-size: 1.3rem;
    @include body-font;
  }
}
@media (min-width: 992px) {
  header.masthead {
    padding-top: calc(6rem + -46px);
    padding-bottom: 6rem;
    h1 {
      font-size: 4.75em;
      line-height: 4rem;
    }
    h2 {
      font-size: 1.75em;
    }

  }
}
