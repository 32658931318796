// Styling for the navbar
#mainNav {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: red;
  height: 60px;
  @include heading-font;
  .navbar-brand {
    color: $white;
  }
  .navbar-nav {
    margin-top: 1rem;
    letter-spacing: 0.0625rem;
    li.nav-item {
      a.nav-link {
        color: $white;
        &:hover {
          color: $primary;
        }
        &:active,
        &:focus {
          color: $white;
        }
        &.active {
          color: $primary;
        }
      }
    }
  }
  .navbar-toggler {
    font-size: 80%;
    padding: 0.8rem;
    margin-top: 0.5rem;
    margin-bottom: -1.5rem;
    z-index: 2;
  }
}
@media(min-width:992px) {
  #mainNav {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    .navbar-brand {
      font-size: 2em;
      -webkit-transition: font-size 0.3s;
      -moz-transition: font-size 0.3s;
      transition: font-size 0.3s;
    }
    .navbar-nav {
      margin-top: 0;
    }
    .navbar-nav > li.nav-item > a.nav-link.active {
      color: $white;
      background: $primary;
      &:active,
      &:focus,
      &:hover {
        color: $white;
        background: $primary;
      }
    }
  }
  #mainNav.navbar-shrink {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    .navbar-brand {
      font-size: 1.5em;
    }
  }
}
